import { useQuery } from '@tanstack/react-query';
import localforage from 'localforage';
import { useEffect } from 'react';

import { getPeopleCurrentOptions } from 'client/@tanstack/react-query.gen';
import type { AccessToken, Person } from 'client/types.gen';

export const useDebouncedEffect = (callback: VoidFunction, delay = 300) => {
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    // Don't use timeout when delay is 0. Doing so would delay the callback
    // execution until the next tick as timeouts always run asynchronously.
    // This may lead to bugs where code depends on this effect running
    // immediately after its dependencies change.
    if (!delay) {
      callback();
    } else {
      timeoutId = setTimeout(() => {
        callback();
      }, delay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [callback, delay]);
};

// TODO: remove
export const useQueryToken = () => {
  const queryToken = useQuery({
    queryFn: async () => {
      const token = await localforage.getItem<AccessToken>('atomToken');
      return token;
    },
    queryKey: [
      {
        entity: 'token',
        scope: 'auth',
      },
    ] as const,
    staleTime: Infinity,
  });

  // return new object to ensure component reload
  return { ...queryToken };
};

// TODO: replace with router context
export const useQueryPeopleCurrent = <T = Person>(
  select?: (data: Person) => T,
) => {
  const queryToken = useQueryToken();

  return useQuery({
    enabled: Boolean(queryToken.data),
    ...getPeopleCurrentOptions(),
    select,
  });
};

export const isPersonAdmin = (person?: Person): boolean =>
  Boolean((person?.roles ?? []).find((role) => role.title === 'Banff Admin'));

export const isPersonAnyTeamMember = (person?: Person): boolean =>
  Boolean(
    (person?.roles ?? []).find(
      (role) => role.title === 'Unassigned Team Member',
    ),
  );
