import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { jwtDecode } from 'jwt-decode';
import localforage from 'localforage';
import queryString from 'query-string';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { routeTree } from './routeTree.gen';

import { client, postToken } from 'client/sdk.gen';
import type { AccessToken } from 'client/types.gen';
import { AppProvider } from 'components/AppProvider';
import { AxiosAuthSubscriber } from 'components/AxiosAuthSubscriber';
import * as serviceWorker from 'config/serviceWorker';
import { queryClient } from 'helpers/query';
import { generateSearch } from 'helpers/router';

// CSS reset, needed to hide some buttons for example
// learn more: https://github.com/ant-design/ant-design/issues/38732
import 'antd/dist/reset.css';

import 'config/i18n';
import 'config/logging';
import 'config/yup';

import 'config/styles.css';
import 'config/tailwind.css';

const container = document.getElementById('root');
const root = createRoot(container!);

// cache ongoing refresh request
let refreshTokenPromise: Promise<string | undefined> | null = null;

client.setConfig({
  auth: async () => {
    const token = await localforage.getItem<AccessToken>('atomToken');
    if (!token) {
      return undefined;
    }

    const { exp } = jwtDecode(token.access_token);
    const currentTime = Math.floor(Date.now() / 1000);
    const bufferTime = 30; // in seconds

    if (exp && exp - bufferTime > currentTime) {
      return token.access_token;
    }

    // handle token refresh, avoid duplicate requests
    if (!refreshTokenPromise) {
      const refreshFn = async () => {
        const { data: newToken, error } = await postToken({
          body: {
            expired_token: token.access_token,
            grant_type: 'refresh_token',
            refresh_token: token.refresh_token,
          },
        });

        if (error) {
          await localforage.removeItem('atomToken');
          window.location.reload();
          return undefined;
        }

        refreshTokenPromise = null;
        await localforage.setItem('atomToken', newToken);
        return newToken?.access_token;
      };

      refreshTokenPromise = refreshFn();
    }

    // return the cached promise so all callers share the result
    return refreshTokenPromise;
  },
  baseURL: import.meta.env.VITE_APP_BASE_URL || '',
  paramsSerializer: (params) => queryString.stringify(params),
});

client.instance.interceptors.response.use(async (response) => {
  if (response.data && typeof response.data === 'object') {
    const token = response.data as AccessToken;
    // check if response has token shape
    if (
      token.token_type === 'bearer' &&
      token.access_token &&
      token.refresh_token
    ) {
      await localforage.setItem('atomToken', token);
      queryClient.invalidateQueries();
      router.invalidate();
    }
  }
  return response;
});

const router = createRouter({
  context: {
    queryClient,
    role: undefined,
    user: undefined,
  },
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  notFoundMode: 'fuzzy',
  parseSearch: (query) => queryString.parse(query),
  routeTree,
  stringifySearch: (search) =>
    generateSearch(search, {
      arrayFormat: 'none',
    }),
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

root.render(
  <StrictMode>
    <AppProvider>
      <ReactQueryDevtools buttonPosition="bottom-right" position="bottom" />
      <AxiosAuthSubscriber />
      <RouterProvider router={router} />
    </AppProvider>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
