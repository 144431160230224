import { DateTime, Interval } from 'luxon';
import { useTranslation } from 'react-i18next';

const defaultRelativeTimeFormatOptions: Intl.RelativeTimeFormatOptions = {
  localeMatcher: 'best fit',
  numeric: 'auto',
  style: 'long',
};

const getDateDifferenceInMilliseconds = (
  a: string | Date,
  b?: string | Date,
): number => {
  const dateA = new Date(a);
  const dateB = b ? new Date(b) : new Date();
  const utcA = getUTCDate(dateA);
  const utcB = getUTCDate(dateB);
  return utcA - utcB;
};

// source: https://stackoverflow.com/a/15289883
// Marked as legacy due to use of `Math.round()`. It's not clear why we'd ever
// want to round up, and changing this behaviour feels risky. Instead, we
// deprecate this method and use the new one moving forward (for now)
export const dateDifferenceInDays_LEGACY = (
  a: string,
  b?: string,
  precise?: boolean,
): number => {
  const msPerDay = 1_000 * 60 * 60 * 24; // 86_400_000ms
  const differenceInDays = getDateDifferenceInMilliseconds(a, b) / msPerDay;
  const difference = precise ? differenceInDays : Math.round(differenceInDays);
  return Object.is(difference, -0) ? 0 : difference;
};

type TRound = 'ceil' | 'floor' | 'none' | 'round';

const getRoundFn = (round?: TRound) => {
  switch (round) {
    // rounding up is useless and unused
    case 'ceil':
      return Math.ceil;
    // disabling rounding is unused
    case 'none':
      return (x: number) => x;
    case 'round':
      return Math.round;
    case 'floor':
    default:
      return Math.floor;
  }
};

type RelativeDateParams = {
  currentDate?: string;
  relativeTimeFormatOptions?: Intl.RelativeTimeFormatOptions;
  round?: TRound;
};

export const getRelativeDate = (
  date: string,
  params?: RelativeDateParams,
): string => {
  const dt = DateTime.fromISO(date);
  const now = DateTime.fromISO(params?.currentDate || new Date().toISOString());
  const isInFuture = dt > now;
  const interval = isInFuture
    ? Interval.fromDateTimes(now, dt)
    : Interval.fromDateTimes(dt, now);
  const roundFn = getRoundFn(params?.round);
  const getDiff = (unit: Intl.RelativeTimeFormatUnit) =>
    roundFn(interval.length(unit));
  const units: ReadonlyArray<Intl.RelativeTimeFormatUnit> = [
    'years',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
    'seconds',
  ] as const;
  const unit = units.find(
    (unit, index) => getDiff(unit) >= 1 || index === units.length - 1,
  )!;
  const modifier = isInFuture ? 1 : -1;
  // TODO: Use dynamic language based on i18next setting
  const relativeTimeFormat = new Intl.RelativeTimeFormat('en', {
    ...defaultRelativeTimeFormatOptions,
    ...params?.relativeTimeFormatOptions,
  });
  return relativeTimeFormat.format(modifier * getDiff(unit), unit);
};

export const getFormattedDate = (date: string): string => {
  const differenceInDays = dateDifferenceInDays_LEGACY(date);
  // TODO: Use dynamic language based on i18next setting
  const relativeTimeFormat = new Intl.RelativeTimeFormat('en', {
    ...defaultRelativeTimeFormatOptions,
  });
  return isDateWithinSevenDays(differenceInDays)
    ? relativeTimeFormat.format(differenceInDays, 'day')
    : getFormattedDateAbsolute(date);
};

export const getFormattedDateAbsolute = (date: string): string =>
  new Date(date).toLocaleDateString('en', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

export const getFormattedDateTime = (date: string): string =>
  new Date(date).toLocaleDateString('en', {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    second: 'numeric',
    year: 'numeric',
  });

export const getUTCDate = (date: Date) =>
  Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );

const isDateWithinSevenDays = (differenceInDays: number) =>
  Math.abs(differenceInDays) < 7;

export const isDateToday = (date: Date) => {
  const now = new Date();
  return (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  );
};

export const useDateRelativeString = (date: string) => {
  const { t } = useTranslation();
  const value = getFormattedDate(date);
  const differenceInDays = dateDifferenceInDays_LEGACY(date);
  return isDateWithinSevenDays(differenceInDays)
    ? value
    : t('date.on', { date: value });
};

export const DATE_RANGE_FILTER_PERIODS = ['3mo', '6mo', '1yr', 'all'] as const;
export type DateRangeFilterPeriod = (typeof DATE_RANGE_FILTER_PERIODS)[number];

export const getPeriodDates = ({
  period,
}: {
  period: DateRangeFilterPeriod;
}): {
  from?: DateTime;
  to?: DateTime;
} => {
  const now = DateTime.fromISO(new Date().toISOString(), {
    zone: 'utc',
  }).startOf('minute');
  switch (period) {
    case '1yr':
      return {
        from: now.minus({ years: 1 }),
        to: undefined,
      };
    case '3mo':
      return {
        from: now.minus({ months: 3 }),
        to: undefined,
      };
    case '6mo':
      return {
        from: now.minus({ months: 6 }),
        to: undefined,
      };
    case 'all':
    default:
      return {
        from: undefined,
        to: undefined,
      };
  }
};

export const toDateWithoutTimezoneOffset = (date: Date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000);
