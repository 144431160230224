import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';
import { z } from 'zod';

import { zFiltersArray } from 'helpers/router';

const privateSearchSchema = z.object({
  action: z.string().optional().catch(undefined),
  advisor_id: zFiltersArray(),
  candidate: z.string().optional().catch(undefined),
  connection_status: zFiltersArray(),
  dialog: z.string().optional().catch(undefined),
  executive_id: zFiltersArray(),
  form: z.string().optional().catch(undefined),
  from: z.string().optional().catch(undefined),
  function_expertise: zFiltersArray(),
  industry_expertise: zFiltersArray(),
  interest_role_board_advisory: zFiltersArray(),
  interest_role_operating: zFiltersArray(),
  modal: z.string().optional().catch(undefined),
  notification_type: zFiltersArray(),
  operating_highlights: zFiltersArray(),
  order: z
    .union([z.literal('asc'), z.literal('desc')])
    .optional()
    .catch(undefined),
  page: z.coerce.number().optional().catch(0),
  results: z.coerce.number().optional().catch(0),
  sort: z.string().optional().catch(undefined),
  status: zFiltersArray(),
  tab: z.string().optional().catch(undefined),
  team_slug: z.string().optional().catch(undefined),
  tier: zFiltersArray(),
});

export const Route = createFileRoute('/_private')({
  beforeLoad: async ({ context, location }) => {
    const { role, user } = context;

    if (!(user && role)) {
      throw redirect({
        search: {
          continue: location.href,
        },
        to: '/login',
      });
    }

    if (posthog.isFeatureEnabled('maintenance')) {
      throw redirect({
        to: '/maintenance',
      });
    }

    return { role, user };
  },
  validateSearch: privateSearchSchema,
});
