import * as Sentry from '@sentry/react';
import { useProfiler } from '@sentry/react';
import type { QueryClient } from '@tanstack/react-query';
import {
  createRootRouteWithContext,
  Outlet,
  useRouterState,
} from '@tanstack/react-router';
import { SpeedInsights } from '@vercel/speed-insights/react';
import localforage from 'localforage';
import { DateTime } from 'luxon';
import posthog from 'posthog-js';
import { useEffect, useRef } from 'react';

import {
  getPeopleCurrentOptions,
  getTeamOptions,
} from 'client/@tanstack/react-query.gen';
import type { AccessToken, Person, PersonRole } from 'client/types.gen';
import { useAxiosAuthInterceptor } from 'helpers/auth';
import { captureOkr } from 'helpers/logging';
import { getPersonRole } from 'helpers/person';
import { lazy } from 'helpers/react';
import { useYup } from 'helpers/yup';

const ErrorPage = lazy(() => import('pages/Error'));

// Automatically refresh page for users who may be leaving tabs open forever.
// The threshold value should be set high enough to avoid affecting majority
// of regular users.
const windowForceRefreshIntervalInSeconds =
  import.meta.env.VITE_APP_WINDOW_FORCE_REFRESH_INTERVAL_IN_SECONDS || 0;

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : lazy(() =>
        import('@tanstack/router-devtools').then((response) => ({
          default: response.TanStackRouterDevtools,
        })),
      );

interface RouterContext {
  queryClient: QueryClient;
  role: PersonRole | undefined;
  user: Person | undefined;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  beforeLoad: async ({ context, params }) => {
    const { queryClient } = context;

    const token = await localforage.getItem<AccessToken>('atomToken');

    let user;

    try {
      if (token) {
        user = await queryClient.ensureQueryData(getPeopleCurrentOptions());
      }
    } catch (error) {
      Sentry.captureException(error);
      await localforage.removeItem('atomToken');
      queryClient.clear();
    }

    if (!user) {
      return { role: undefined, user };
    }

    const teamId = 'teamId' in params ? params.teamId : undefined;

    const currentTeam = teamId
      ? await queryClient.ensureQueryData(
          getTeamOptions({ path: { team_slug: teamId as string } }),
        )
      : undefined;

    const role = getPersonRole(
      user,
      currentTeam ? currentTeam.slug : undefined,
    );

    return { role, user };
  },
  component: RootComponent,
  errorComponent: ErrorPage,
});

function RootComponent() {
  useProfiler('App');
  useYup();

  useAxiosAuthInterceptor();

  const router = useRouterState();

  const { user } = Route.useRouteContext();

  const refSessionStart = useRef<DateTime>();

  useEffect(() => {
    if (windowForceRefreshIntervalInSeconds > 0) {
      refSessionStart.current = DateTime.fromISO(new Date().toISOString()).plus(
        { seconds: windowForceRefreshIntervalInSeconds },
      );
    }
  }, []);

  useEffect(() => {
    const threshold = refSessionStart.current;
    if (threshold && user?.id) {
      const now = DateTime.fromISO(new Date().toISOString());
      if (now > threshold) {
        captureOkr('refresh_due_to_stale_client', {
          timeout_seconds: windowForceRefreshIntervalInSeconds,
          user_id: user.id,
        });
        window.location.reload();
      }
    }
  }, [router.location.pathname, user?.id]);

  useEffect(() => {
    posthog.capture('$pageview');
  }, [router.location]);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <SpeedInsights
        debug={process.env.NODE_ENV !== 'production'}
        route={router.location.pathname}
        sampleRate={1.0}
      />
      <Outlet />
      <TanStackRouterDevtools />
    </Sentry.ErrorBoundary>
  );
}
