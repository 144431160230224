import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';
import { z } from 'zod';

import { zTaskCreated, zTaskDueDate, zTaskStatus } from 'client/zod.gen';
import { zFiltersArray } from 'helpers/router';

const schema = z.object({
  assignee_uuid: zFiltersArray(),
  candidate_uuid: zFiltersArray(),
  created: zFiltersArray(zTaskCreated),
  due_date: zFiltersArray(zTaskDueDate),
  filtersDialog: z
    .union([
      z.literal('assignee_uuid'),
      z.literal('candidate_uuid'),
      z.literal('created'),
      z.literal('due_date'),
      z.literal('status'),
    ])
    .optional()
    .catch(undefined),
  status: zFiltersArray(zTaskStatus),
  task: z.string().optional(),
});

export type SearchSchema = z.infer<typeof schema>;

export const Route = createFileRoute('/_private/_route/_internal/tasks')({
  beforeLoad: () => {
    if (!posthog.isFeatureEnabled('project-tasks')) {
      throw redirect({ replace: true, to: '/' });
    }
  },
  validateSearch: schema,
});
