import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_private/_route/_internal/tasks/')({
  beforeLoad: () => {
    throw redirect({
      replace: true,
      search: {
        created: [],
        due_date: [],
        status: [],
      },
      to: '/tasks/me',
    });
  },
});
